import React from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Danger from "components/Typography/Danger.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import {injectIntl, FormattedMessage} from 'react-intl';


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      error: "",
      emailValidation: "",
      passwordValidation: ""
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout( function() { this.setState({ cardAnimaton: "" }); }.bind(this), 700 );
  }
  handleChange(e, required) {
    var validationPropery = (e.target.name).replace(".", "") + "Validation";
    if (required && e.target.value === '') {
        this.setState({ [validationPropery] : "error"})
    } else {
        this.setState({ [validationPropery] : "success"})
    }
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleSubmit = async event => {
    const { intl } = this.props;
    event.preventDefault();

    var validationErrors = false;
    if (this.state.email === undefined || this.state.email === '') {
      validationErrors = true;
      this.setState({ "emailValidation" : "error"})
    }
    if (this.state.password === undefined || this.state.password === '') {
      validationErrors = true;
      this.setState({ "passwordValidation" : "error"})
    }

    for (var stateKey of Object.keys(this.state)) {
      if (stateKey.endsWith("Validation") && this.state[stateKey] === "error") {
        validationErrors = true;
      }
    }

    if (validationErrors) {
      this.setState({error: intl.formatMessage({id:"login.form.validationErrors"})})
      return;
    } else {
      this.setState({
        error: "",
        emailValidation: "success",
        passwordValidation : "success"
      })
    }

    try {
     Auth.signIn(this.state.email, this.state.password)
       .then(user => {
        console.log(user)
        this.props.history.push("/")}
       )
       .catch(err => this.setState({
          error: intl.formatMessage({id:"login.error." + err.code}),
          emailValidation: "error",
          passwordValidation : "error"
          })
        );
    } catch (e) {
      alert(e.message);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.handleSubmit}>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                  <h4 className={classes.cardTitle}><FormattedMessage id="loginpage.form.title" defaultMessage="Log in"/></h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText={<FormattedMessage id="loginpage.form.field.email" defaultMessage="Email*"/>}
                    id="email"
                    error={this.state.emailValidation === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.email,
                      name: "email",
                      onChange: event => this.handleChange(event, true),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText={<FormattedMessage id="loginpage.form.field.password" defaultMessage="Password*"/>}
                    id="password"
                    error={this.state.passwordValidation === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      name: "password",
                      value: this.state.password,
                      onChange: event => this.handleChange(event, true),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Danger>{this.state.error}</Danger>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type="submit" color="rose" simple size="lg" block>
                    <FormattedMessage id="loginpage.form.submit" defaultMessage="Let's go"/>
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(loginPageStyle)(LoginPage));
