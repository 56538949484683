import React from "react";
import { Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";

import {FormattedMessage} from 'react-intl';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Axios from "axios";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ReactTable from "react-table";
import {trackPromise} from "react-promise-tracker";
import moment from "moment";

class AppointmentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  async componentDidMount() {
    try {
      let currentSession = await Auth.currentSession();
      this.setState({ session: currentSession });
        debugger;
        let appointmentDataResponse = await trackPromise(Axios.get(
          `${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments/${this.props.match.params.appointmentIdentifier}`,
          {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
        ));
        this.setState({ data: appointmentDataResponse.data });
    } catch (err) {
      if (err === "No current user") {
        this.props.history.push("/auth/login-page");
      }
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.data === null) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>...</h4>
                </CardText>
              </CardHeader>
              <CardBody />
            </Card>
          </GridItem>
        </GridContainer>
      );
    } else {
      return (
        <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}><FormattedMessage id="appointmentform.legend.appointment_details" defaultMessage="Appointment details"/></h4>
                </CardText>
              </CardHeader>
              <CardBody>
                  <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}><FormattedMessage id="appointmentform.field.plannedDate" defaultMessage="Planned date"/></FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                          <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.customerNameValidation === "error"}
                              success={this.state.customerNameValidation === "success"}
                              inputProps={{name: "customer.name", disabled: true, value: moment(this.state.data.plannedDate).format('DD/MM/YYYY HH:mm'), type: "text"}}
                          />
                      </GridItem>
                  </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}><FormattedMessage id="appointmentform.field.seller" defaultMessage="Seller"/></FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        <CustomInput
                            formControlProps={{ fullWidth: true }}
                            error={this.state.customerNameValidation === "error"}
                            success={this.state.customerNameValidation === "success"}
                            inputProps={{name: "customer.name", disabled: true, value: this.state.data.user, type: "text"}}
                        />
                    </GridItem>
                </GridContainer>

                  <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}><FormattedMessage id="appointmentform.field.remarks" defaultMessage="Remarks"/></FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                          <CustomInput
                              formControlProps={{ fullWidth: true }}
                              error={this.state.customerEmailValidation === "error"}
                              success={this.state.customerEmailValidation === "success"}
                              inputProps={{name: "customer.name", disabled: true, value: this.state.data.remarks, type: "text"}}
                          />
                      </GridItem>
                  </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="rose" text>
                        <CardText color="rose">
                            <h4 className={classes.cardTitle}><FormattedMessage id="appointmentform.legend.customer_information" defaultMessage="Customer information"/></h4>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontal}><FormattedMessage id="appointmentform.field.customer_name" defaultMessage="Customer name"/></FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={9}>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    error={this.state.customerNameValidation === "error"}
                                    success={this.state.customerNameValidation === "success"}
                                    inputProps={{name: "customer.name", disabled: true, value: this.state.data.customer.name, type: "text"}}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={2}>
                                <FormLabel className={classes.labelHorizontal}><FormattedMessage id="appointmentform.field.customer_email" defaultMessage="Customer email"/></FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={9}>
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    error={this.state.customerEmailValidation === "error"}
                                    success={this.state.customerEmailValidation === "success"}
                                    inputProps={{name: "customer.name", disabled: true, value: this.state.data.customer.email, type: "text"}}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer style={{ height: "75px" }}/>
                    </CardBody>
                </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" text>
                        <CardText color="rose">
                            <h4 className={classes.cardTitle}><FormattedMessage id="appointmentform.legend.products" defaultMessage="Scanned products"/></h4>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={this.state.data.products}
                            loading={this.state.loading}
                            columns={[
                                { Header: "Artikel nummer", accessor: "articleNumber", Cell: row => <a href={'/admin/edit-product/' + row.value}>{row.value}</a> },
                                { Header: "Naam", accessor: "name" },
                                { Header: "Prijs", id: "price", accessor: product => product.price.value, filterable: false },
                                { Header: "Groothandel prijs", id: "wholeSalePrice", accessor: product => product.price.wholesaleValue, filterable: false },
                                { Header: "",  sortable: false, filterable: false }
                            ]}
                            defaultPageSize={20}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                            noDataText=""
                            rowsText=""
                            previousText="Vorige"
                            nextText="Volgende"
                            pageText="Pagina"
                            ofText="van"
                        />

                    </CardBody>
                </Card>
            </GridItem>

        </GridContainer>
      );
    }
  }
}

export default withStyles(regularFormsStyle)(AppointmentForm);
