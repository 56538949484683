import React from "react";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import calendarStyle from "assets/jss/material-dashboard-pro-react/views/calendarStyle.jsx";

import {Auth} from "aws-amplify";
import Axios from 'axios';
import FormControl from "@material-ui/core/FormControl";
import 'moment/locale/nl'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FormattedMessage} from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import {trackPromise} from "react-promise-tracker";

moment.locale('nl')
const localizer = BigCalendar.momentLocalizer(moment);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      users: [],
      session: null,
      showAlert: false,
      tour: {
          customer: {},
          seller: {}
      },
      alert: null
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    Auth.currentSession()
        .then(data => {
          this.setState({session : data})
          this.loadEvents();
          this.loadUsers();
        })
        .catch(err => {
          console.log(err)
          if (err === 'No current user') {
            this.props.history.push("/auth/login-page");
          }
        });
  }

  loadUsers() {
      trackPromise(
          Axios.get(`${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/users`, {params: {page: 0, pageSize: 50}, headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}})
              .then((res) => {
                  this.setState({
                      users: res.data
                  });
          })
      );
  }

  loadEvents() {
      trackPromise(
          Axios.get(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments`, {headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}})
          .then((res) => {

              let events = []
              for (var appointment of res.data) {
                  if (appointment.status === 'FINISHED') {
                      this.addEventFromAppointment(events, appointment, "green");
                  } else if (appointment.status === 'CANCELLED') {
                      this.addEventFromAppointment(events, appointment, "red");
                  } else {
                      this.addEventFromAppointment(events, appointment, "orange");
                  }
              }

              this.setState({
                  events: events
              });
          })
      );
  }

  addEventFromAppointment(events, appointment, color) {
      events.push(
          {
              title: appointment.customer != null ? appointment.customer.name : 'Geen informatie',
              start: new Date(appointment.plannedDate),
              end: new Date(appointment.plannedDate),
              allDay: false,
              color: color,
              tour: appointment
          }
      )
  }

  handleDateTimeChange(value) {
      let tour = Object.assign({}, this.state.tour);
      tour.plannedDate = value.toDate();
      this.setState({ tour })
  }

  handleInputChange(e, required) {
      let tour = Object.assign({}, this.state.tour);
      if (e.target.name.startsWith("customer.")) {
          tour.customer[e.target.name.replace("customer.", "")] = e.target.value;
      } else if (e.target.name.startsWith("seller.")) {
          tour.seller[e.target.name.replace("seller.", "")] = e.target.value;
      } else {
          tour[e.target.name] = e.target.value;
      }
      this.validateField(e.target.name, e.target.value, required);
      this.setState({ tour })
  }

  validateField(fieldName, fieldValue, required) {
      var validationPropery = fieldName.replace(".", "") + "Validation";
      if (required && (fieldValue === '' || fieldValue === undefined)) {
          this.setState({[validationPropery]: "error"})
      } else {
          this.setState({[validationPropery]: "success"})
      }
  }

  selectedEvent(event) {
      if (event.tour.customer == null) {
          event.tour.customer = {};
      }
      if (event.tour.seller == null) {
          event.tour.seller = {};
      }
      event.tour.plannedDate = new Date(event.tour.plannedDate)
      this.setState({
          showAlert: true,
          tour: event.tour
      });
  }

  addNewEventAlert(slotInfo) {
      this.setState({
          showAlert: true,
          tour: {
              plannedDate: new Date(),
              status: 'PLANNED',
              seller: {
                email: this.state.session.accessToken.payload.username
              },
              customer: {}
          }
      });
  }

  async validateFields() {
      await this.validateField("customer.name", this.state.tour.customer.name, true);
      await this.validateField("customer.email", this.state.tour.customer.email, true);
  }

  async updateEvent(e) {
      await this.validateFields()
      for (var stateKey of Object.keys(this.state)) {
          if (stateKey.endsWith("Validation") && this.state[stateKey] === "error") {
              return;
          }
      }

      let data = Object.assign({}, this.state.tour);
      await trackPromise(Axios.put(
          `${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments/${data.appointmentIdentifier}`,
          JSON.stringify(data),
          {headers: {authorization: `Bearer ${this.state.session.idToken.jwtToken}`}}
      ));
    this.hideAlert();
    this.loadEvents();
  }

  async createNewEvent(e) {
      await this.validateFields();
      for (var stateKey of Object.keys(this.state)) {
          if (stateKey.endsWith("Validation") && this.state[stateKey] === "error") {
              return;
          }
      }

      let data = Object.assign({}, this.state.tour);
      await trackPromise(Axios.post(
          `${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments`,
          JSON.stringify(data),
          {headers: {authorization: `Bearer ${this.state.session.idToken.jwtToken}`}}
      ));
      this.hideAlert();
      this.loadEvents();
  }

  async deleteEvent(e) {
      let data = Object.assign({}, this.state.tour);
      await trackPromise(Axios.delete(
          `${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments/${data.appointmentIdentifier}`,
          {headers: {authorization: `Bearer ${this.state.session.idToken.jwtToken}`}}
      ));
      this.hideAlert();
      this.loadEvents();
  }

  hideAlert() {
    this.setState({
        tour: {
            customer: {},
            seller: {}
        },
        customernameValidation: null,
        customeremailValidation: null,
        showAlert: false
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color ? (backgroundColor = backgroundColor + event.color) : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  render() {
    return (
      <div>
          <SweetAlert
              showCancel={this.state.tour.status !== "FINISHED"}
              closeOnClickOutside={false}
              allowEscape={false}
              showCloseButton={true}
              show={this.state.showAlert}
              style={{ display: "block", marginTop: "-350px"}}
              cancelBtnText={this.state.tour.appointmentIdentifier ? "Afspraak wissen" : "Annuleren"}
              title={this.state.tour.appointmentIdentifier ? "Afspraak aanpassen" : "Afspraak maken"}
              onConfirm={event => this.state.tour.appointmentIdentifier ? this.updateEvent(event) : this.createNewEvent(event)}
              onCancel={ event => this.state.tour.appointmentIdentifier ? this.deleteEvent(event) :  this.hideAlert()}
              confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
              cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.cancel}>
              <form>
                  <FormControl fullWidth>
                      <Datetime
                          timeFormat="HH:mm"
                          value={this.state.tour.plannedDate}
                          onChange={value => this.handleDateTimeChange(value)}
                          inputProps={{ placeholder: "Tijdstip" }}/>
                  </FormControl>
                  <CustomInput
                      success={this.state.customeremailValidation === "success"}
                      error={this.state.customeremailValidation === "error"}
                      labelText={<FormattedMessage id="calendar.form.field.appointment.customer.email" defaultMessage="Email"/>}
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                          name: "customer.email",
                          value: this.state.tour.customer.email,
                          onChange: event => this.handleInputChange(event, true),
                          type: "email",
                          endAdornment:
                              this.state.customeremailValidation === "error" ? (
                                  <InputAdornment position="end">
                                      <Close className={this.props.classes.danger} />
                                  </InputAdornment> ) : (
                                  undefined
                              )
                      }}
                  />
                  <CustomInput
                      success={this.state.customernameValidation === "success"}
                      error={this.state.customernameValidation === "error"}
                      labelText={<FormattedMessage id="calendar.form.field.appointment.customer.name" defaultMessage="Name"/>}
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                          name: "customer.name",
                          value: this.state.tour.customer.name,
                          onChange: event => this.handleInputChange(event, true),
                          type: "text",
                          endAdornment:
                              this.state.customernameValidation === "error" ? (
                                  <InputAdornment position="end">
                                      <Close className={this.props.classes.danger} />
                                  </InputAdornment> ) : (
                                  undefined
                              )
                      }}
                  />
                  <CustomInput
                      success={this.state.remarksValidation === "success"}
                      error={this.state.remarksValidation === "error"}
                      labelText={<FormattedMessage id="calendar.form.field.appointment.remarks" defaultMessage="Remarks"/>}
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                          multiline: true, rows: 5,
                          name: "remarks",
                          value: this.state.tour.remarks,
                          onChange: event => this.handleInputChange(event, true),
                          type: "text",
                          endAdornment:
                              this.state.remarksValidation === "error" ? (
                                  <InputAdornment position="end">
                                      <Close className={this.props.classes.danger} />
                                  </InputAdornment> ) : (
                                  undefined
                              )
                      }}
                  />
                  <FormControl fullWidth className={this.props.classes.selectFormControl}>
                      <Select
                          MenuProps={{ className: "modalSelectStatus", style: {zIndex: 6300} }}
                          classes={{ select: this.props.classes.select }}
                          value={this.state.tour.seller.email}
                          inputProps={{name: "seller.email", onChange: event => this.handleInputChange(event)}}>
                          <MenuItem disabled classes={{ root: this.props.classes.selectMenuItem }}><FormattedMessage id="calendar.form.field.appointment.user" defaultMessage="Kies verkoper"/></MenuItem>
                          {this.state.users.map((value, index) => {
                              return <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value={value.email}>{value.firstName} {value.lastName}</MenuItem>
                          })}
                      </Select>
                  </FormControl>
                  <FormControl fullWidth className={this.props.classes.selectFormControl}>
                      <Select
                          MenuProps={{ className: "modalSelectStatus", style: {
                                  zIndex: 6300
                              } }}
                          classes={{ select: this.props.classes.select }}
                          value={this.state.tour.status}
                          inputProps={{name: "status", onChange: event => this.handleInputChange(event)}}>
                          <MenuItem disabled classes={{ root: this.props.classes.selectMenuItem }}><FormattedMessage id="calendar.form.field.appointment.status" defaultMessage="Choose status"/></MenuItem>
                          <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="PLANNED">
                              <FormattedMessage id="tour.field.status.PLANNED" defaultMessage="Planned"/>
                          </MenuItem>
                          <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="PENDING">
                              <FormattedMessage id="tour.field.status.PENDING" defaultMessage="Pending"/>
                          </MenuItem>
                          <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="FINISHED">
                              <FormattedMessage id="tour.field.status.FINISHED" defaultMessage="Finished"/>
                          </MenuItem>
                          <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="CANCELLED">
                              <FormattedMessage id="tour.field.status.CANCELLED" defaultMessage="Cancelled"/>
                          </MenuItem>
                      </Select>
                  </FormControl>

                  <div>
                      <br/>
                      <small>*</small> <FormattedMessage id="calendar.form.mandatory.fields" defaultMessage="Mandatory fields"/>
                      <br/>
                  </div>

              </form>
          </SweetAlert>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody calendar>
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={this.state.events}
                  messages={{
                    date: 'Datum',
                    time: 'Tijdstip',
                    event: 'Afspraak',
                    allDay: 'Volledige dag',
                    week: 'Week',
                    work_week: 'Werk Week',
                    day: 'Dag',
                    month: 'Maand',
                    previous: 'Vorige',
                    next: 'Volgende',
                    yesterday: 'Gisteren',
                    tomorrow: 'Morgen',
                    today: 'Vandaag',
                    agenda: 'Agenda',
                    noEventsInRange: 'Geen afspraken.',
                    showMore: function showMore(total) {
                        return "+" + total + " meer";
                    }
                  }}
                  defaultView="month"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={event => this.selectedEvent(event)}
                  onSelectSlot={slotInfo => this.addNewEventAlert(slotInfo)}
                  eventPropGetter={this.eventColors}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(calendarStyle)(Calendar);
