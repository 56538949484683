import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {Auth} from "aws-amplify";
import {trackPromise} from "react-promise-tracker";
import Axios from "axios";
import CardText from "../../components/Card/CardText";
import {FormattedMessage, injectIntl} from "react-intl";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/core/SvgIcon/SvgIcon";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class UserProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
    }

    async componentDidMount() {
        try {
            let currentSession = await Auth.currentSession();
            this.setState({session: currentSession});
            let userDataResponse = await trackPromise(Axios.get(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/users/${currentSession.idToken.payload.email}`,
                {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
            ));
            this.setState({user: userDataResponse.data});
        } catch (err) {
            if (err === "No current user") {
                this.props.history.push("/auth/login-page");
            }
        }
    }

    render() {
        const {classes} = this.props;
        const { intl } = this.props;
        if (this.state.user === null || this.state.user === undefined) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" text>
                                <CardText color="rose">
                                    <h4 className={classes.cardTitle}>...</h4>
                                </CardText>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        } else {
            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardText color="rose">
                                        <h4 className={classes.cardTitle}>Mijn profiel</h4>
                                    </CardText>
                                </CardHeader>
                                <CardBody>

                                    <legend>
                                        <strong><FormattedMessage id="userprofile.legend.general" defaultMessage="General"/></strong>
                                    </legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Voornaam"
                                                id="first-name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.user.firstName,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Achternaam"
                                                id="last-name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.user.lastName,
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <br/>
                                    <legend>
                                        <strong><FormattedMessage id="userprofile.legend.contactinformation" defaultMessage="Contact information"/></strong>
                                    </legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Email"
                                                id="company-disabled"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.user.email,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Telefoon"
                                                id="company-disabled"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.user.phone,
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <br/>
                                    <legend>
                                        <strong><FormattedMessage id="userprofile.legend.permissions" defaultMessage="Permissions"/></strong>
                                    </legend>

                                    <GridContainer>
                                        {this.state.user.roles.map((value, index) => {
                                            return <GridItem xs={12} sm={12} md={12}><FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            checked={true}
                                                            disabled={true}
                                                            readOnly={true}
                                                            value={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}
                                                        />
                                                    }
                                                    label={intl.formatMessage({id:"roles." + value})}
                                            />
                                            </GridItem>
                                        })}
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}

export default injectIntl(withStyles(regularFormsStyle)(UserProfile));
