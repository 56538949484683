import React from "react";
import {Auth} from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Add from "@material-ui/icons/Add";
import ReactTable from "react-table";

import {injectIntl, FormattedMessage} from 'react-intl';

import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Axios from "axios";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import FormHelperText from '@material-ui/core/FormHelperText';
import {trackPromise} from "react-promise-tracker";

class RegularForms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            newProduct: false,
            productRelationType: undefined,
            productRelationData: [],
            productRelationPopupVisible: false,
            showConfirmDeleteProductRelation: false,
            productRelationTypeValidation: "success"
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRelationProductArticleNumberInputChange = this.handleRelationProductArticleNumberInputChange.bind(this);
        this.handleRelationProductNameInputChange = this.handleRelationProductNameInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        try {
            let currentSession = await Auth.currentSession();
            this.setState({session: currentSession});

            let companySettingsResponse = await trackPromise(Axios.get(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings`,
                {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
            ));
            this.setState({companysettings: companySettingsResponse.data});

            if (this.props.match.params.articleNumber !== undefined) {
                let productDataResponse = await trackPromise(Axios.get(
                    `${process.env.REACT_APP_PURQR_API_BASE_URL}/products/${this.props.match.params.articleNumber}`,
                    {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
                ));
                this.addSpecificationsAsProperties(productDataResponse);
                this.addGalleryImagesAsProperties(productDataResponse);
                this.setState({data: productDataResponse.data});
            } else {
                let productDataResponse = await trackPromise(Axios.get(
                    `${process.env.REACT_APP_PURQR_API_BASE_URL}/products/initial`,
                    {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
                ));
                this.setState({newProduct: true});
                this.addSpecificationsAsProperties(productDataResponse);
                this.setState({data: productDataResponse.data});
            }
        } catch (err) {
            if (err === "No current user") {
                this.props.history.push("/auth/login-page");
            } else {
                alert(err.message);
            }
        }
    }

    deleteProductRelation() {
        for (var i = this.state.data.relations.length; i--;) {
            if (this.state.data.relations[i].articleNumber === this.state.selectedProductRelation) {
                this.state.data.relations.splice(i, 1);
            }
        }
        this.setState({ showConfirmDeleteProductRelation: false, selectedProductRelation: null });
    }

    /**
     * Loop over each specification and add as a first level property so we can bind to it
     * @param productDataResponse
     */
    addSpecificationsAsProperties(productDataResponse) {
        for (var specification of productDataResponse.data.specifications) {
            productDataResponse.data[specification.key] = {
                value: specification.value,
                key: specification.key,
                type: specification.type,
                label: specification.label
            }
        }
    }

    addGalleryImagesAsProperties(productDataResponse) {
        if (productDataResponse.data.images !== undefined) {
            if (productDataResponse.data.images[0] !== undefined) {
                productDataResponse.data['galleryFirstImage'] = productDataResponse.data.images[0];
            }
            if (productDataResponse.data.images[1] !== undefined) {
                productDataResponse.data['gallerySecondImage'] = productDataResponse.data.images[1];
            }
            if (productDataResponse.data.images[2] !== undefined) {
                productDataResponse.data['galleryThirdImage'] = productDataResponse.data.images[2];
            }
            if (productDataResponse.data.images[3] !== undefined) {
                productDataResponse.data['galleryFourthImage'] = productDataResponse.data.images[3];
            }
            if (productDataResponse.data.images[4] !== undefined) {
                productDataResponse.data['galleryFifthImage'] = productDataResponse.data.images[4];
            }
        }
    }

    handleInputChange(e, required, number) {
        let fieldName = e.target.name;
        let fieldValue = e.target.value;
        this.validate(fieldName, fieldValue, required, number);
    }

    handleRelationProductArticleNumberInputChange(e) {
      this.setState({"productRelationArticleNumberInput" : e.target.value});
    }

    handleRelationProductNameInputChange(e) {
      this.setState({"productRelationArticleNameInput" : e.target.value});
    }

    handleRelationProductTypeChange(e) {
        this.setState({"productRelationType" : e.target.value});
        this.setValidationProperty("productRelationType", true, e.target.value, false);
    }

    async searchProductsForProductRelations(event) {
        let productDataResponse = [];
        if (this.state.productRelationArticleNumberInput !== undefined && this.state.productRelationArticleNumberInput.length > 1) {
            productDataResponse = await trackPromise(Axios.get(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/products?articleNumber=${this.state.productRelationArticleNumberInput}`,
                {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
            ));
        } else if (this.state.productRelationArticleNameInput !== undefined && this.state.productRelationArticleNameInput.length > 1) {
            productDataResponse = await trackPromise(Axios.get(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/products?name=${this.state.productRelationArticleNameInput}`,
                {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
            ));
            debugger;
        } else {
            productDataResponse = await trackPromise(Axios.get(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/products`,
                {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
            ));
        }

        this.setState({
        productRelationData: productDataResponse.data.map((prop, key) => {
          return {
            id: key,
            articleNumber: prop.articleNumber,
            name: prop.name,
            actions: (
              <div className="actions-right">
                <Button justIcon round color="success" className="edit" onClick={() => {
                        this.setValidationProperty("productRelationType", true, this.state.productRelationType, false);
                        if (this.state.productRelationType !== undefined) {
                            let data = Object.assign({}, this.state.data);
                            if (data.relations === undefined) {
                                data.relations = [];
                            }
                            data.relations.push({articleNumber : prop.articleNumber, name: prop.name, relationType: this.state.productRelationType});
                            this.setState({data});
                            this.setState({'productRelationPopupVisible': false});
                            this.setState({'productRelationType': undefined})
                        }
                    }}>
                  <Add/>
                </Button>
              </div>
            )
          };
        }),
        pages: productDataResponse.data.pages
      })

    }

    async validate(fieldName, fieldValue, required, number) {
        let data = Object.assign({}, this.state.data);
        if (fieldName.startsWith("price.")) {
            data.price[fieldName.replace("price.", "")] =  fieldValue;
        } else if (fieldName.startsWith("specifications.")) {
            let specificationKey = fieldName.replace("specifications.", "").replace(".value", "");
            // Update the specification in the array with the new fieldValue
            for (let specification of data.specifications) {
                if (specification.key === specificationKey) {
                    specification.value = fieldValue;
                    // Set the fieldValue to the first level property for binding purposes
                    data[specificationKey] = {
                        key: specificationKey,
                        value: fieldValue,
                        label: specification.label
                    };
                }
            }
        } else {
            data[fieldName] = fieldValue;
        }
        this.setValidationProperty(fieldName, required, fieldValue, number);
        this.setStateSynchronous({data});
    }

    setValidationProperty(fieldName, required, fieldValue, number) {
        var validationProperty = (fieldName).replace(".", "") + "Validation";
        if (required && (fieldValue === '' || fieldValue === undefined)) {
            this.setStateSynchronous({[validationProperty]: "required"})
        } else if (number && isNaN(fieldValue)) {
            this.setStateSynchronous({[validationProperty]: "invalidformat"})
        } else {
            this.setStateSynchronous({[validationProperty]: "success"})
        }
    }

    async setStateSynchronous(stateUpdate) {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        await this.validate("articleNumber", this.state.data.articleNumber, true);
        await this.validate("name", this.state.data.name, true);
        await this.validate("price.value", this.state.data.price.value, true, true);
        await this.validate("price.currency", this.state.data.price.currency, true);
        await this.validate("price.priceType", this.state.data.price.priceType, true);

        for (var stateKey of Object.keys(this.state)) {
            if (stateKey.endsWith("Validation") && this.state[stateKey] !== "success") {
                return;
            }
        }
        await trackPromise(this.uploadAndSaveImages());

        let data = Object.assign({}, this.state.data);

        data.price.value = Number(data.price.value);
        data.price.wholesaleValue = Number(data.price.wholesaleValue);

        if (this.props.match.params.articleNumber !== undefined) {
            await trackPromise(Axios.put(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/products/${this.props.match.params.articleNumber}`,
                JSON.stringify(data),
                {headers: {authorization: `Bearer ${this.state.session.idToken.jwtToken}`}}
            ));
        } else {
            await trackPromise(Axios.post(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/products`,
                JSON.stringify(data),
                {headers: {authorization: `Bearer ${this.state.session.idToken.jwtToken}`}}
            ));
        }
        // Navigate back to products overview list screen
        this.props.history.push("/admin/products");
    }

    async uploadAndSaveImages() {
        let overviewImageUrl = await this.uploadImage(this.refs.overviewImage);
        let galleryFirstImageUrl = await this.uploadImage(this.refs.galleryFirstImage);
        let gallerySecondImageUrl = await this.uploadImage(this.refs.gallerySecondImage);
        let galleryThirdImageUrl = await this.uploadImage(this.refs.galleryThirdImage);
        let galleryFourthImageUrl = await this.uploadImage(this.refs.galleryFourthImage);
        let galleryFifthImageUrl = await this.uploadImage(this.refs.galleryFifthImage);

        let data = Object.assign({}, this.state.data);
        data.overviewImage = overviewImageUrl;
        data.images = [];

        if (galleryFirstImageUrl != null) {
            data.images.push(galleryFirstImageUrl);
        }
        if (gallerySecondImageUrl != null) {
            data.images.push(gallerySecondImageUrl);
        }
        if (galleryThirdImageUrl != null) {
            data.images.push(galleryThirdImageUrl);
        }
        if (galleryFourthImageUrl != null) {
            data.images.push(galleryFourthImageUrl);
        }
        if (galleryFifthImageUrl != null) {
            data.images.push(galleryFifthImageUrl);
        }

        this.setState({data});
    }

    async uploadImage(uploadImageComponent) {
        let uploadUrl = null;
        if (uploadImageComponent.state.file !== null) {
            // POST filename and contentType to lambda in order to request a signedURL that can be used to upload directly to S3
            let signedUrlResponse = await Axios.post(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/products/assets?fileName=${uploadImageComponent.state.file.name}&contentType=${uploadImageComponent.state.file.type}`,
                null,
                {
                    headers: {
                        authorization: `Bearer ${this.state.session.idToken.jwtToken}`
                    }
                }
            );
            var signedUrl = signedUrlResponse.data;
            uploadUrl = signedUrl.substr(0, signedUrl.indexOf(uploadImageComponent.state.file.name)) + uploadImageComponent.state.file.name;
            // Use the signedURL and PUT the data of the file to S3
            await Axios.put(signedUrl, uploadImageComponent.state.file, {headers: {"Content-Type": uploadImageComponent.state.file.type}});
        } else if (uploadImageComponent.state.defaultImageUrl !== null) {
            uploadUrl = uploadImageComponent.state.defaultImageUrl
        }
        return uploadUrl;
    }

    render() {
        const {classes} = this.props;
        if (this.state.data === null) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" text>
                                <CardText color="rose">
                                    <h4 className={classes.cardTitle}>...</h4>
                                </CardText>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        } else {
            return (
                <GridContainer>
                    <SweetAlert
                        showCancel
                        closeOnClickOutside={false}
                        allowEscape={false}
                        showCloseButton={true}
                        show={this.state.showConfirmDeleteProductRelation}
                        style={{ display: "block"}}
                        cancelBtnText={<FormattedMessage id="general.button.cancel" defaultMessage="Cancel"/>}
                        title={"Product relatie wissen ?"}
                        onConfirm={event => this.deleteProductRelation()}
                        onCancel={ () => {this.setState({ showConfirmDeleteProductRelation : false, selectedProduct: undefined });}}
                        confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                        cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.cancel}>
                        Bent u zeker ? Deze actie kan niet ongedaan gemaakt worden.
                    </SweetAlert>
                    <SweetAlert
                        showCancel={true}
                        showConfirm={false}
                        closeOnClickOutside={true}
                        allowEscape={true}
                        show={this.state.productRelationPopupVisible}
                        style={{ display: "block", marginTop: "-350px", width: "750px"}}
                        title={"Product relatie toevoegen"}
                        cancelBtnText={<FormattedMessage id="general.button.cancel" defaultMessage="Cancel"/>}
                        onCancel={ event => this.setState({'productRelationPopupVisible': false})}
                        cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.cancel}>
                          <FormControl fullWidth className={this.props.classes.selectFormControl}>
                              <Select MenuProps={{ className: "modalSelectStatus", style: {zIndex: 6300} }}
                                  value={this.state.productRelationType}
                                  classes={{ select: this.props.classes.select }}
                                  inputProps={{ onChange: event => this.handleRelationProductTypeChange(event)}}>

                                  <MenuItem disabled classes={{ root: this.props.classes.selectMenuItem }}>
                                    <FormattedMessage id="productform.relationmodal.field.relationtype" defaultMessage="Choose relation type"/>
                                  </MenuItem>
                                  <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="PART_OF">
                                      <FormattedMessage id="product.relation.type.PART_OF" defaultMessage="Part of"/>
                                  </MenuItem>
                                  <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="CONSISTS_OUT">
                                      <FormattedMessage id="product.relation.type.CONSISTS_OUT" defaultMessage="Consists out"/>
                                  </MenuItem>
                                  <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="COLOR_VARIANT">
                                      <FormattedMessage id="product.relation.type.COLOR_VARIANT" defaultMessage="Color variant"/>
                                  </MenuItem>
                                  <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="SIZE_VARIANT">
                                      <FormattedMessage id="product.relation.type.SIZE_VARIANT" defaultMessage="Size variant"/>
                                  </MenuItem>
                                  <MenuItem classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value="ALTERNATIVE">
                                      <FormattedMessage id="product.relation.type.ALTERNATIVE" defaultMessage="Alternative"/>
                                  </MenuItem>
                              </Select>
                              <FormHelperText style={{color: "#c7254e", backgroundColor: "#f9f2f4", borderRadius: "4px", padding: "7px", width: "60px"}} hidden={this.state.productRelationTypeValidation === "" || this.state.productRelationTypeValidation === "success"}>Verplicht</FormHelperText>
                          </FormControl>

                            <CustomInput
                            formControlProps={{fullWidth: true}}
                            labelText={<FormattedMessage id="productform.relationmodal.field.articleNumber" defaultMessage="Article number"/>}
                            inputProps={{
                                name: "relationProductArticleNumber",
                                type: "text",
                                onChange: event => this.handleRelationProductArticleNumberInputChange(event),
                                endAdornment: <InputAdornment position="end"><Search onClick={(event) => { this.searchProductsForProductRelations(event) }}/></InputAdornment>
                            }}/>

                            <CustomInput
                            formControlProps={{fullWidth: true}}
                            labelText={<FormattedMessage id="productform.relationmodal.field.articleName" defaultMessage="Article name"/>}
                            inputProps={{
                                name: "relationProductArticleName",
                                type: "text",
                                onChange: event => this.handleRelationProductNameInputChange(event),
                                endAdornment: <InputAdornment position="end"><Search onClick={(event) => { this.searchProductsForProductRelations(event) }}/></InputAdornment>
                            }}/>

                            <ReactTable
                              data={this.state.productRelationData}
                              filterable
                              columns={[
                                { Header: "Artikel nummer", accessor: "articleNumber", filterable: false, sortable: false},
                                { Header: "Naam", accessor: "name", filterable: false, sortable: false },
                                { Header: "", accessor: "actions", sortable: false, filterable: false }
                              ]}
                              defaultPageSize={5}
                              showPaginationTop={false}
                              showPageSizeOptions={false}
                              showPaginationBottom={true}
                              className="-striped -highlight"
                              noDataText=""
                              rowsText=""
                              previousText="Vorige"
                              nextText="Volgende"
                              pageText="Pagina"
                              ofText="van"/>
                    </SweetAlert>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" text>
                                <CardText color="rose">
                                    <h4 className={classes.cardTitle}>
                                        {this.state.newProduct ? <FormattedMessage id="productform.title.createProduct"
                                                                                   defaultMessage="Create product"/> :
                                            <FormattedMessage id="productform.title.updateProduct"
                                                              defaultMessage="Update product"/>}
                                    </h4>
                                </CardText>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={this.handleSubmit}>

                                    <legend><strong><FormattedMessage id="productform.legend.basic_product_information" defaultMessage="Basic product information"/></strong></legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.article_number" defaultMessage="Article number"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                error={this.state.articleNumberValidation === "required"}
                                                success={this.state.articleNumberValidation === "success"}
                                                inputProps={{
                                                    name: "articleNumber",
                                                    onChange: event => this.handleInputChange(event, true),
                                                    value: this.state.data.articleNumber,
                                                    type: "text",
                                                    endAdornment:
                                                        this.state.articleNumberValidation === "required" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>) : (
                                                            undefined
                                                        )
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.articleNumberValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required"
                                                                            defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.article_name"
                                                defaultMessage="Article name"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                error={this.state.nameValidation === "required"}
                                                success={this.state.nameValidation === "success"}
                                                inputProps={{
                                                    name: "name",
                                                    value: this.state.data.name,
                                                    onChange: event => this.handleInputChange(event, true),
                                                    type: "text",
                                                    endAdornment:
                                                        this.state.nameValidation === "required" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>) : (
                                                            undefined
                                                        )
                                                }}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.nameValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required" defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.product_description"
                                                defaultMessage="Product description"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                error={this.state.descriptionValidation === "required"}
                                                success={this.state.descriptionValidation === "success"}
                                                inputProps={{
                                                    name: "description",
                                                    value: this.state.data.description,
                                                    onChange: event => this.handleInputChange(event, false),
                                                    multiline: true,
                                                    rows: 5,
                                                    endAdornment:
                                                        this.state.descriptionValidation === "required" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>) : (
                                                            undefined
                                                        )
                                                }}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.descriptionValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required"
                                                                            defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.color" defaultMessage="Color"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                error={this.state.colorValidation === "required"}
                                                success={this.state.colorValidation === "success"}
                                                inputProps={{
                                                    name: "color",
                                                    value: this.state.data.color,
                                                    onChange: event => this.handleInputChange(event),
                                                    type: "text",
                                                    endAdornment:
                                                        this.state.colorValidation === "required" ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>) : (
                                                            undefined
                                                        )
                                                }}/>
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.colorValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required"
                                                                            defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.category"
                                                defaultMessage="Choose category"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <br/>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <Select
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: classes.select}}
                                                    value={this.state.data.category}
                                                    inputProps={{
                                                        name: "category",
                                                        onChange: event => this.handleInputChange(event)
                                                    }}>
                                                    <MenuItem disabled
                                                              classes={{root: classes.selectMenuItem}}><FormattedMessage
                                                        id="productform.field.category"
                                                        defaultMessage="Choose category"/></MenuItem>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value=""></MenuItem>

                                                    {this.state.companysettings.categories.map((category, index) => {
                                                        return <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}} value={category.key}>{category.label}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.categoryValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required"
                                                                            defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>


                                    <br/>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.overview_image" defaultMessage="Overview image"/></strong>

                                    </legend>
                                    <ImageUpload
                                        ref="overviewImage"
                                        defaultImageUrl={this.state.data.overviewImage}
                                        addButtonProps={{color: "rose", round: true}}
                                        changeButtonProps={{color: "rose", round: true}}
                                        removeButtonProps={{color: "danger", round: true}}
                                    />
                                    <br/>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.status" defaultMessage="Status"/></strong></legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.status"
                                                defaultMessage="Choose status"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <br/>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <Select
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: classes.select}}
                                                    value={this.state.data.status}
                                                    inputProps={{
                                                        name: "status",
                                                        onChange: event => this.handleInputChange(event)
                                                    }}>
                                                    <MenuItem disabled
                                                              classes={{root: classes.selectMenuItem}}><FormattedMessage
                                                        id="productform.field.status"
                                                        defaultMessage="Choose status"/></MenuItem>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value="VISIBLE_IN_SHOWROOM_NOT_IN_STOCK">
                                                        <FormattedMessage
                                                            id="productform.field.status.VISIBLE_IN_SHOWROOM_NOT_IN_STOCK"
                                                            defaultMessage="Visible in showroom & Not in stock"/>
                                                    </MenuItem>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value="VISIBLE_IN_SHOWROOM_IN_STOCK">
                                                        <FormattedMessage
                                                            id="productform.field.status.VISIBLE_IN_SHOWROOM_IN_STOCK"
                                                            defaultMessage="Visible in showroom & Not in stock"/>
                                                    </MenuItem>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value="NOT_VISIBLE_IN_SHOWROOM_NOT_IN_STOCK">
                                                        <FormattedMessage
                                                            id="productform.field.status.NOT_VISIBLE_IN_SHOWROOM_NOT_IN_STOCK"
                                                            defaultMessage="Not visible in showroom & Not in stock"/>
                                                    </MenuItem>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value="NOT_VISIBLE_IN_SHOWROOM_IN_STOCK">
                                                        <FormattedMessage
                                                            id="productform.field.status.NOT_VISIBLE_IN_SHOWROOM_IN_STOCK"
                                                            defaultMessage="Not visible in showroom & In stock"/>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.statusValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required"
                                                                            defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>

                                    <br/>
                                    <br/>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.price_information" defaultMessage="Price information"/></strong></legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.price" defaultMessage="Price"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                success={this.state.pricevalueValidation === "success"}
                                                error={this.state.pricevalueValidation === "required" || this.state.pricevalueValidation === "invalidformat"}
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    name: "price.value",
                                                    value: this.state.data.price.value,
                                                    onChange: event => this.handleInputChange(event, true, true),
                                                    type: "text",
                                                    endAdornment:
                                                        (this.state.pricevalueValidation === "required" || this.state.pricevalueValidation === "invalidformat") ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>) : (
                                                            undefined
                                                        )
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.pricevalueValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required" defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                            {this.state.pricevalueValidation === "invalidformat" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.invalidformat" defaultMessage="invalidformat"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.wholesalePrice" defaultMessage="Wholesale price"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                success={this.state.pricewholesaleValueValidation === "success"}
                                                error={this.state.pricewholesaleValueValidation === "required" || this.state.pricewholesaleValueValidation === "invalidformat"}
                                                inputProps={{
                                                    name: "price.wholesaleValue",
                                                    value: this.state.data.price.wholesaleValue,
                                                    onChange: event => this.handleInputChange(event, false, true),
                                                    type: "text",
                                                    endAdornment:
                                                        (this.state.pricewholesaleValueValidation === "required" || this.state.pricewholesaleValueValidation === "invalidformat") ? (
                                                            <InputAdornment position="end">
                                                                <Close className={classes.danger}/>
                                                            </InputAdornment>) : (
                                                            undefined
                                                        )
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.pricewholesaleValueValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required" defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                            {this.state.pricewholesaleValueValidation === "invalidformat" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.invalidformat" defaultMessage="invalidformat"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.currency" defaultMessage="Currency"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                success={this.state.currencyValidation === "success"}
                                                error={this.state.currencyValidation === "required"}
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    name: "price.currency",
                                                    disabled: true,
                                                    value: this.state.data.price.currency,
                                                    onChange: event => this.handleInputChange(event, true),
                                                    type: "text"
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.currencyValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required"
                                                                            defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.priceType"
                                                defaultMessage="Price type"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <br/>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <Select
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: classes.select}}
                                                    value={this.state.data.price.priceType}
                                                    inputProps={{
                                                        value: this.state.data.price.priceType,
                                                        name: "price.priceType",
                                                        onChange: event => this.handleInputChange(event, true)
                                                    }}>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value="PIECE">/<FormattedMessage
                                                        id="productform.field.pricetype.PIECE" defaultMessage="Piece"/></MenuItem>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value="WHOLE">/<FormattedMessage
                                                        id="productform.field.pricetype.WHOLE" defaultMessage="Whole"/></MenuItem>
                                                    <MenuItem classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value="SQUARE_METER">/<FormattedMessage
                                                        id="productform.field.pricetype.SQUARE_METER"
                                                        defaultMessage="&#x33A1;"/></MenuItem>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            {this.state.priceTypeValidation === "required" ?
                                                <FormLabel className={classes.labelLeftHorizontal}>
                                                    <code><FormattedMessage id="form.field.validation.required"
                                                                            defaultMessage="required"/></code>
                                                </FormLabel> : undefined}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.minimalSalesUnit"
                                                defaultMessage="Min. Sales unit"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                success={this.state.minimalSalesUnitValidation === "success"}
                                                error={this.state.minimalSalesUnitValidation === "required"}
                                                inputProps={{
                                                    type: "text",
                                                    value: this.state.data.minimalSalesUnit,
                                                    name: "minimalSalesUnit",
                                                    onChange: event => this.handleInputChange(event),
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <br/>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.gallery_images" defaultMessage="Gallery images"/></strong></legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <ImageUpload
                                                ref="galleryFirstImage"
                                                defaultImageUrl={this.state.data.galleryFirstImage}
                                                addButtonProps={{color: "rose", round: true}}
                                                changeButtonProps={{color: "rose", round: true}}
                                                removeButtonProps={{color: "danger", round: true}}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            <ImageUpload
                                                ref="gallerySecondImage"
                                                defaultImageUrl={this.state.data.gallerySecondImage}
                                                addButtonProps={{color: "rose", round: true}}
                                                changeButtonProps={{color: "rose", round: true}}
                                                removeButtonProps={{color: "danger", round: true}}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            <ImageUpload
                                                ref="galleryThirdImage"
                                                defaultImageUrl={this.state.data.galleryThirdImage}
                                                addButtonProps={{color: "rose", round: true}}
                                                changeButtonProps={{color: "rose", round: true}}
                                                removeButtonProps={{color: "danger", round: true}}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            <ImageUpload
                                                ref="galleryFourthImage"
                                                defaultImageUrl={this.state.data.galleryFourthImage}
                                                addButtonProps={{color: "rose", round: true}}
                                                changeButtonProps={{color: "rose", round: true}}
                                                removeButtonProps={{color: "danger", round: true}}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2}>
                                            <ImageUpload
                                                ref="galleryFifthImage"
                                                defaultImageUrl={this.state.data.galleryFifthImage}
                                                addButtonProps={{color: "rose", round: true}}
                                                changeButtonProps={{color: "rose", round: true}}
                                                removeButtonProps={{color: "danger", round: true}}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.specifications"defaultMessage="Specifications"/></strong></legend>

                                    {this.state.companysettings.specifications.map((specification, index) => {
                                      return <GridContainer>
                                              {(specification.categories === undefined || specification.categories.includes(this.state.data.category)) &&
                                              <GridContainer>
                                               <GridItem xs={12} sm={2}>
                                                   <FormLabel className={classes.labelHorizontal}>{specification.label}</FormLabel>
                                               </GridItem>
                                               <GridItem xs={12} sm={8}>
                                                 <br/>
                                                  {specification.type === 'BOOLEAN' &&
                                                    <FormControl fullWidth className={classes.selectFormControl}>
                                                        <Select
                                                          MenuProps={{className: classes.selectMenu}}
                                                          classes={{select: classes.select}}
                                                          value={this.state.data[specification.key].value}
                                                          inputProps={{
                                                              value: this.state.data[specification.key].value,
                                                              name: "specifications." + specification.key + ".value",
                                                              onChange: event => this.handleInputChange(event)
                                                          }}>
                                                          <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}} value="true">
                                                              <FormattedMessage id="form.field.generic.yes" defaultMessage="Yes"/>
                                                          </MenuItem>
                                                          <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}} value="false">
                                                              <FormattedMessage id="form.field.generic.no" defaultMessage="No"/>
                                                          </MenuItem>
                                                        </Select>
                                                      </FormControl>
                                                  }
                                                  {specification.type === 'NUMBER' &&
                                                    <FormControl fullWidth className={classes.selectFormControl}>
                                                        <CustomInput
                                                            formControlProps={{fullWidth: true}}
                                                            inputProps={{
                                                                type: "text",
                                                                value: this.state.data[specification.key].value,
                                                                name: "specifications." + specification.key + ".value",
                                                                onChange: event => this.handleInputChange(event)
                                                            }}
                                                        />
                                                      </FormControl>
                                                  }
                                                  {specification.type === 'STRING' &&
                                                    <FormControl fullWidth className={classes.selectFormControl}>
                                                        <CustomInput
                                                            formControlProps={{fullWidth: true}}
                                                            inputProps={{
                                                                type: "text",
                                                                value: this.state.data[specification.key].value,
                                                                name: "specifications." + specification.key + ".value",
                                                                onChange: event => this.handleInputChange(event)
                                                            }}
                                                        />
                                                      </FormControl>
                                                  }
                                                  {specification.type === 'CHOICE' &&
                                                    <FormControl fullWidth className={classes.selectFormControl}>
                                                      <Select
                                                        MenuProps={{className: classes.selectMenu}}
                                                        classes={{select: classes.select}}
                                                        value={this.state.data[specification.key].value}
                                                        inputProps={{
                                                            value: this.state.data[specification.key].value,
                                                            name: "specifications." + specification.key + ".value",
                                                            onChange: event => this.handleInputChange(event)
                                                        }}>
                                                        {specification.values.map((possibleValue, index) => {
                                                          return <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}} value={possibleValue}>{possibleValue}</MenuItem>
                                                        })}

                                                      </Select>
                                                    </FormControl>
                                                  }
                                               </GridItem>
                                               </GridContainer>
                                             }
                                           </GridContainer>
                                    })}

                                    <br/>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.relations" defaultMessage="Product relations"/></strong></legend>

                                    <ReactTable
                                        data={this.state.data.relations}
                                        filterable
                                        columns={[
                                            { Header: "Relatie type", accessor: "relationType", Cell: ({ original }) => (<FormattedMessage id={`product.relation.type.${original.relationType}`} defaultMessage={`${original.relationType}`} />) ,filterable: true, sortable: false},
                                          { Header: "Artikel nummer", accessor: "articleNumber", filterable: false, sortable: false},
                                          { Header: "Naam", accessor: "name", filterable: false, sortable: false },
                                          { Header: "", accessor: "articleNumber", Cell: ({ original }) => (
                                                                <div className="actions-right">
                                                                                <Button justIcon round color="warning" className="edit" onClick={() => {
                                                                                        this.setState({
                                                                                            selectedProductRelation: original.articleNumber,
                                                                                            showConfirmDeleteProductRelation: true
                                                                                        });
                                                                                    }}>
                                                                                  <Close/>
                                                                                </Button>
                                                                              </div>
                                                              ) , sortable: false, filterable: false }
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationTop={false}
                                        showPageSizeOptions={false}
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                        noDataText=""
                                        rowsText=""
                                        previousText="Vorige"
                                        nextText="Volgende"
                                        pageText="Pagina"
                                        ofText="van"/>

                                    <Button color="rose" className={classes.updateProductButton} onClick={(event) => { this.setState({"productRelationPopupVisible": true}) }} >
                                        <FormattedMessage id="productform.button.addRelation" defaultMessage="Add product relation"/>
                                    </Button>

                                    <br/>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.supplier_information" defaultMessage="Supplier information"/></strong></legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.supplier" defaultMessage="Supplier"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    type: "text",
                                                    value: this.state.data.supplier,
                                                    name: "supplier",
                                                    onChange: event => this.handleInputChange(event)
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}><FormattedMessage
                                                id="productform.field.supplierArticleNumber"
                                                defaultMessage="Supplier article number"/></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    type: "text",
                                                    value: this.state.data.supplierArticleNumber,
                                                    name: "supplierArticleNumber",
                                                    onChange: event => this.handleInputChange(event)
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <br/>
                                    <br/>

                                    <legend><strong><FormattedMessage id="productform.legend.manufacturer_information" defaultMessage="Manufacturer information"/></strong></legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                              <FormattedMessage id="productform.field.manufacturer" defaultMessage="Manufacturer"/>
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    type: "text",
                                                    value: this.state.data.manufacturer,
                                                    name: "manufacturer",
                                                    onChange: event => this.handleInputChange(event)
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                              <FormattedMessage id="productform.field.manufacturerArticleNumber" defaultMessage="Manufacturer Article number"/>
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    type: "text",
                                                    value: this.state.data.manufacturerArticleNumber,
                                                    name: "manufacturerArticleNumber",
                                                    onChange: event => this.handleInputChange(event)
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                              <FormattedMessage id="productform.field.manufacturerUrl" defaultMessage="Manufacturer URL"/>
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8}>
                                            <CustomInput
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    type: "text",
                                                    value: this.state.data.manufacturerUrl,
                                                    name: "manufacturerUrl",
                                                    onChange: event => this.handleInputChange(event)
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <Button type="submit" color="rose" className={classes.updateProductButton}>
                                        <FormattedMessage id="productform.button.save" defaultMessage="Create product"/>
                                    </Button>
                                    <Clearfix/>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }
}

export default injectIntl(withStyles(regularFormsStyle)(RegularForms));

