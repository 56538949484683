import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import AccessTime from "@material-ui/icons/AccessTime";
import PersonPin from "@material-ui/icons/PersonPin";
import Queue from "@material-ui/icons/Queue";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import {
  lineChart,
  pieChart
} from "variables/charts.jsx";


import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import ReactTable from "react-table";
import {Auth} from "aws-amplify";
import {trackPromise} from "react-promise-tracker";
import Axios from "axios";
import {FormattedMessage} from "react-intl";

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      summary: null,
    };
  }

  async componentDidMount() {
    try {
      let currentSession = await Auth.currentSession();
      this.setState({ session: currentSession });

        let summaryResponse = await trackPromise(Axios.get(
            `${process.env.REACT_APP_PURQR_API_BASE_URL}/summary`,
            {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
        ));
        this.setState({ summary: summaryResponse.data });
    } catch (err) {
      if (err === "No current user") {
        this.props.history.push("/auth/login-page");
      } else {
        alert(err.message);
      }
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.summary === null) {
      return (<div>
        <GridContainer>
        </GridContainer>
      </div>);
    } else {
      return (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <PersonPin />
                    </CardIcon>
                    <p className={classes.cardCategory}><FormattedMessage id="dashboard.title.planned_appointments" defaultMessage="Planned appointments"/></p>
                    <h3 className={classes.cardTitle}>{this.state.summary.plannedAppointmentsToday}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      <FormattedMessage id="dashboard.daterange.today" defaultMessage="Today"/>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <PersonPin/>
                    </CardIcon>
                    <p className={classes.cardCategory}><FormattedMessage id="dashboard.title.finished_appointments" defaultMessage="Finished appointments"/></p>
                    <h3 className={classes.cardTitle}>{this.state.summary.finishedAppointmentsToday}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      <FormattedMessage id="dashboard.daterange.today" defaultMessage="Today"/>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <Queue />
                    </CardIcon>
                    <p className={classes.cardCategory}><FormattedMessage id="dashboard.title.products_added_to_appointments" defaultMessage="Products added to appointments"/></p>
                    <h3 className={classes.cardTitle}>{this.state.summary.productsAddedToAppointmentToday}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      <FormattedMessage id="dashboard.daterange.today" defaultMessage="Today"/>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color="warning" className={classes.cardHeaderHover}>
                    <ChartistGraph
                        className="ct-chart-white-colors"
                        data={this.state.summary.appointmentsPerDay}
                        type="Line"
                        options={lineChart.options}
                        listener={lineChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}><FormattedMessage id="dashboard.title.finished_appointments" defaultMessage="Finished appointments"/></h4>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> <FormattedMessage id="dashboard.accesstime.previous_seven_days" defaultMessage="Previous 7 days"/>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <Card chart className={classes.cardHover}>
                  <CardHeader color="success" className={classes.cardHeaderHover}>
                    <ChartistGraph
                        className="ct-chart-white-colors"
                        data={this.state.summary.productsAddedToAppointmentPerDay}
                        type="Bar"
                        options={lineChart.options}
                        listener={lineChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}><FormattedMessage id="dashboard.title.products_added_to_appointments" defaultMessage="Products added to appointments"/></h4>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> <FormattedMessage id="dashboard.accesstime.previous_seven_days" defaultMessage="Previous 7 days"/>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="warning" icon>
                    <CardIcon color="warning">
                      <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}><FormattedMessage id="dashboard.title.populair_products" defaultMessage="Most popular products"/></h4>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                        data={this.state.summary.popularProducts}
                        columns={[
                          { Header: "Artikel nummer", accessor: "articleNumber", sortable: false, filterable: false, Cell: row => <a href={'/admin/edit-product/' + row.value}>{row.value}</a> },
                          { Header: "Naam", accessor: "name", sortable: false, filterable: false },
                          { Header: "# toegevoegd aan afspraak", accessor: "addedToAppointmentCount", sortable: false, filterable: false, Cell: row => <div style={{ paddingLeft: "40px" }}>{row.value}</div> }
                        ]}
                        noDataText={"Geen afgewerkte afspraken met producten"}
                        defaultPageSize={10}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                      <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}><FormattedMessage id="dashboard.title.populair_categories" defaultMessage="Most popular categories"/></h4>
                  </CardHeader>
                  <CardBody>
                    <ChartistGraph data={this.state.summary.popularProductCategories} type="Pie" options={pieChart.options}/>
                  </CardBody>
                  <CardFooter stats className={classes.cardFooter}>
                    <h6 className={classes.legendTitle}><FormattedMessage id="dashboard.label.legend" defaultMessage="Legend"/></h6>
                    {this.state.summary.popularProductCategories.legendLabels.length > 0 && <i className={"fas fa-circle " + classes.info} /> }
                    {this.state.summary.popularProductCategories.legendLabels.length > 0 ? this.state.summary.popularProductCategories.legendLabels[0] : ''}
                    {this.state.summary.popularProductCategories.legendLabels.length > 1 && <i className={"fas fa-circle " + classes.warning} /> }
                    {this.state.summary.popularProductCategories.legendLabels.length > 1 ? this.state.summary.popularProductCategories.legendLabels[1] : ''}
                    {this.state.summary.popularProductCategories.legendLabels.length > 2 && <i className={"fas fa-circle " + classes.danger} /> }
                    {this.state.summary.popularProductCategories.legendLabels.length > 2 ? this.state.summary.popularProductCategories.legendLabels[2] : ''}
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>


          </div>
      );
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
