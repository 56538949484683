import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {Auth} from "aws-amplify";
import {trackPromise} from "react-promise-tracker";
import Axios from "axios";
import CardText from "../../components/Card/CardText";
import {injectIntl} from "react-intl";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/core/SvgIcon/SvgIcon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "../../components/CustomInput/CustomInput";

class CompanySettingsGeneral extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
    }

    async componentDidMount() {
        try {
            let currentSession = await Auth.currentSession();
            this.setState({session: currentSession});
            let companysettingsDataResponse = await trackPromise(Axios.get(
                `${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings`,
                {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
            ));
            this.setState({companysettings: companysettingsDataResponse.data});
        } catch (err) {
            if (err === "No current user") {
                this.props.history.push("/auth/login-page");
            }
        }
    }

    render() {
        const {classes} = this.props;
        const { intl } = this.props;
        if (this.state.companysettings === null || this.state.companysettings === undefined) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" text>
                                <CardText color="rose">
                                    <h4 className={classes.cardTitle}>...</h4>
                                </CardText>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        } else {
            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardText color="rose">
                                        <h4 className={classes.cardTitle}>Algemene instellingen</h4>
                                    </CardText>
                                </CardHeader>
                                <CardBody>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Productcode prefix"
                                                id="identifierPrefix-disabled"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.companysettings.identifierPrefix,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Website"
                                                id="website-disabled"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.companysettings.website,
                                                }}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Logo"
                                                id="logo-disabled"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.companysettings.logo,
                                                }}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Email"
                                                id="email-disabled"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.companysettings.email,
                                                }}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12}><FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    checked={this.state.companysettings.enablePublicProductView}
                                                    disabled={true}
                                                    readOnly={true}
                                                    value={this.state.companysettings.enablePublicProductView}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            label={intl.formatMessage({id:"companysettings.general.enablePublicProductView"})}
                                        />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}><FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    checked={this.state.companysettings.enableCustomerNotifications}
                                                    disabled={true}
                                                    readOnly={true}
                                                    value={this.state.companysettings.enableCustomerNotifications}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            label={intl.formatMessage({id:"companysettings.general.enableCustomerNotifications"})}
                                        />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}

export default injectIntl(withStyles(regularFormsStyle)(CompanySettingsGeneral));
