import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

import { Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";

import {injectIntl, FormattedMessage} from 'react-intl';

import { trackPromise } from 'react-promise-tracker';

import productsStyle from "assets/jss/material-dashboard-pro-react/views/productsStyle.jsx";

class Specifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showConfirmDeleteAlert: false,
      selectedProduct: undefined,
      session: null
    };
  }
  componentDidMount() {
    Auth.currentSession()
      .then(data => {
        this.setState({session : data})})
      .catch(err => {
        console.log(err)
        if (err === 'No current user') {
          this.props.history.push("/auth/login-page");
        }
      });
  }

  deleteSpecification() {
    trackPromise(
      Axios.delete(`${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/specifications/${this.state.specificationkey}`,
        {
          headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}
        }).then((response) => {
          this.loadUserData(0, 50);
        })
    );
    this.setState({ showConfirmDeleteAlert: false });
  }

  loadSpecificationData() {
    trackPromise(
      Axios.get(`${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/specifications`, {params: {page: 0, pageSize: 50}, headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}})
        .then((res) => {
          // Update react-table
          this.setState({
            data: res.data.map((prop, key) => {
              return {
                id: key,
                key: prop.key,
                label: prop.label,
                type: prop.type,
                category: prop.category,
                visibleInApp: prop.displaySettings != null && prop.displaySettings.showInApp === true ? "\u2713" : "",
                showOnPublicView: prop.displaySettings != null && prop.displaySettings.showOnPublicView === true ? "\u2713" : "",
                /*
                actions: (
                  <div className="actions-right">
                    <Button component={Link} justIcon round
                        to={{pathname: "/admin/edit-user/" + prop.email}} color="info"
                        className="edit">
                      <Create/>
                    </Button>{" "}
                    <Button justIcon round color="danger" className="remove" onClick={() => {
                      this.setState({
                        email: prop.email,
                        showConfirmDeleteAlert: true
                      });
                    }}>
                      <Close/>
                    </Button>{" "}
                  </div>
                )
                */
              };
            }),
            pages: res.data.pages
          })
        })
    );
  }

  render() {
    if (this.state.session === null) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </CardHeader>
              <CardBody/>
            </Card>
          </GridItem>
        </GridContainer>
        )
    } else {
      return (
        <div>
          <SweetAlert
            showCancel
            closeOnClickOutside={false}
            allowEscape={false}
            showCloseButton={true}
            show={this.state.showConfirmDeleteAlert}
            style={{ display: "block"}}
            cancelBtnText={<FormattedMessage id="general.button.cancel" defaultMessage="Cancel"/>}
            title={"Product wissen ?"}
            onConfirm={event => this.deleteProduct()}
            onCancel={ () => {this.setState({ showConfirmDeleteAlert : false, selectedProduct: undefined });}}
            confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
            cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.cancel}>
            Bent u zeker ? Deze actie kan niet ongedaan gemaakt worden.
        </SweetAlert>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Assignment />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    onFetchData={(state, instance) => {
                      this.loadSpecificationData(state.page, state.pageSize)
                    }}
                    filterable
                    columns={[
                      { Header: "Code", accessor: "key" },
                      { Header: "Label", accessor: "label" },
                      { Header: "Type", accessor: "type" },
                      { Header: "Categorie", accessor: "category" },
                      { Header: "Zichtbaar door verkoper", accessor: "visibleInApp", sortable: false, filterable: false },
                      { Header: "Zichtbaar door klant", accessor: "showOnPublicView", sortable: false, filterable: false },
                      { Header: "", accessor: "actions", sortable: false, filterable: false }
                    ]}
                    defaultPageSize={50}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    noDataText=""
                    rowsText=""
                    previousText="Vorige"
                    nextText="Volgende"
                    pageText="Pagina"
                    ofText="van"/>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default injectIntl(withStyles(productsStyle)(Specifications));
