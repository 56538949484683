import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Auth } from "aws-amplify";

import { Link } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Visibility from "@material-ui/icons/Visibility";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Axios from 'axios';

import moment from "moment";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import {injectIntl} from 'react-intl';
import {trackPromise} from "react-promise-tracker";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class FinishedAppointments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      session: null
    };
  }
  componentDidMount() {
    Auth.currentSession()
      .then(data => {
        this.setState({session : data})})
      .catch(err => {
        console.log(err)
        if (err === 'No current user') {
          this.props.history.push("/auth/login-page");
        }
      });
  }
  render() {
      function loadAppointmentData() {
          trackPromise(
          Axios.get(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments?status=FINISHED`, {headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}})
              .then((res) => {
                  debugger;
                  // Update react-table
                  this.setState({
                      data: res.data.map((prop, key) => {
                          return {
                              id: key,
                              appointmentIdentifier: prop.appointmentIdentifier,
                              plannedDate: prop.plannedDate,
                              seller: prop.seller.firstName + ' ' + prop.seller.lastName,
                              customerName: prop.customer ? prop.customer.name : '',
                              customerEmail: prop.customer ? prop.customer.email : '',
                              numberOfProducts: prop.products ? prop.products.length : '',
                              actions: (
                                  <div className="actions-right">
                                      <Button component={Link} justIcon round
                                              to={{pathname: "/admin/appointments/" + prop.appointmentIdentifier}} color="info"
                                              className="edit">
                                          <Visibility/>
                                      </Button>{" "}
                                  </div>
                              )
                          };
                      }),
                      pages: res.data.pages,
                      loading: false
                  })
              })
          );
      }

    if (this.state.session === null) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </CardHeader>
              <CardBody/>
            </Card>
          </GridItem>
        </GridContainer>
        )
    } else {
      return (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Assignment />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    loading={this.state.loading}
                    onFetchData={(state, instance) => {
                      // show the loading overlay
                      this.setState({loading: true})
                      // fetch your data
                        loadAppointmentData.call(this);
                    }}
                    filterable
                    columns={[
                      { Header: "Afspraak ID", accessor: "appointmentIdentifier" },
                      { Header: "Datum afspraak", id: 'plannedDate', accessor: tour => moment(tour.plannedDate).format('DD/MM/YYYY HH:mm'), filterable: false },
                      { Header: "Verkoper", accessor: "seller" },
                      { Header: "Klant naam", accessor: "customerName" },
                      { Header: "Klant email", accessor: "customerEmail" },
                      { Header: "#Producten gescand", accessor: "numberOfProducts", filterable: false, sortable: false, Cell: row => <div style={{ paddingLeft: "20px" }}>{row.value}</div>},
                      { Header: "", accessor: "actions", sortable: false, filterable: false }
                    ]}
                    defaultPageSize={20}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    loadingText="Afspraken ophalen..."
                    noDataText=""
                    rowsText=""
                    previousText="Vorige"
                    nextText="Volgende"
                    pageText="Pagina"
                    ofText="van"
                />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );
    }
  }
}

export default injectIntl(withStyles(styles)(FinishedAppointments));
