import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

import {Auth} from "aws-amplify";

import {Link} from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Create from "@material-ui/icons/Create";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Print from "@material-ui/icons/Print";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";

import {injectIntl, FormattedMessage} from 'react-intl';

import {trackPromise} from 'react-promise-tracker';

import productsStyle from "assets/jss/material-dashboard-pro-react/views/productsStyle.jsx";

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showConfirmDeleteAlert: false,
            selectedProduct: undefined,
            session: null
        };
    }

    async componentDidMount() {
        try {
            let currentSession = await Auth.currentSession();
            this.setState({session: currentSession});
        } catch (err) {
            if (err === "No current user") {
                this.props.history.push("/auth/login-page");
            } else {
                alert(err.message);
            }
        }

        let companySettingsResponse = await trackPromise(Axios.get(
            `${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings`,
            {headers: {authorization: "Bearer " + this.state.session.idToken.jwtToken}}
        ));
        this.setState({companysettings: companySettingsResponse.data});
    }

    deleteProduct() {
        trackPromise(
            Axios.delete(`${process.env.REACT_APP_PURQR_API_BASE_URL}/products/${this.state.articleNumber}`,
                {
                    headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}
                }).then((response) => {
                this.loadProductData();
            })
        );
        this.setState({showConfirmDeleteAlert: false});
    }

    loadProductData(page, pagesize, filters) {
        const {intl} = this.props;
        let articleNumberFilter = filters.filter(function(filter) { return filter.id === 'articleNumber'});
        trackPromise(
            Axios.get(`${process.env.REACT_APP_PURQR_API_BASE_URL}/products`, {
                params: {page: 0, pageSize: 50, articleNumber: articleNumberFilter !== null && articleNumberFilter[0] !== undefined ? articleNumberFilter[0].value : null},
                headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}
            })
                .then((res) => {
                    // Update react-table
                    this.setState({
                        data: res.data.map((prop, key) => {
                            return {
                                id: key,
                                articleNumber: prop.articleNumber,
                                name: prop.name,
                                category: prop.category,
                                status: intl.formatMessage({id: "productform.field.status." + prop.status}),
                                price: (prop.price.value !== undefined ? prop.price.value + " " + prop.price.currency + " / " + intl.formatMessage({id: "productform.field.pricetype." + prop.price.priceType}) : ''),
                                actions: (
                                    <div className="actions-right">
                                        {this.state.companysettings.enablePublicProductView ?
                                            <Button component={Link} justIcon round
                                                    to={{pathname: `${process.env.REACT_APP_PURQR_PUBLIC_BASE_URL}/products/` + prop.tenantId + "/" + prop.articleNumber}}
                                                    target="_blank" color="success"
                                                    className="view">
                                                <Visibility/>
                                            </Button>
                                            : undefined}
                                        <Button component={Link} justIcon round
                                                to={{pathname: "/admin/edit-product/" + prop.articleNumber}}
                                                color="info"
                                                className="edit">
                                            <Create/>
                                        </Button>{" "}
                                        <Button justIcon round color="warning" className="print" onClick={() => {
                                            trackPromise(
                                                Axios.post(`${process.env.REACT_APP_PURQR_API_BASE_URL}/labels`,
                                                    {
                                                        templateName: this.state.companysettings.labelLayouts[0].name,
                                                        labelData: JSON.stringify(prop)
                                                    }, {
                                                        headers: {'authorization': 'Bearer ' + this.state.session.idToken.jwtToken}
                                                    }).then((response) => {
                                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                                    const link = document.createElement('a');
                                                    link.href = url;
                                                    link.setAttribute('download', prop.articleNumber + '.label');
                                                    document.body.appendChild(link);
                                                    link.click();
                                                }));
                                        }}>
                                            <Print/>
                                        </Button>{" "}
                                        <Button justIcon round color="danger" className="remove" onClick={() => {
                                            this.setState({
                                                articleNumber: prop.articleNumber,
                                                showConfirmDeleteAlert: true
                                            });
                                        }}>
                                            <Close/>
                                        </Button>{" "}
                                    </div>
                                )
                            };
                        }),
                        pages: res.data.pages
                    })
                })
        );
    }

    render() {
        if (this.state.session === null) {
            return (
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <Assignment/>
                                </CardIcon>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        } else {
            return (
                <div>
                    <SweetAlert
                        showCancel
                        closeOnClickOutside={false}
                        allowEscape={false}
                        showCloseButton={true}
                        show={this.state.showConfirmDeleteAlert}
                        style={{display: "block"}}
                        cancelBtnText={<FormattedMessage id="general.button.cancel" defaultMessage="Cancel"/>}
                        title={"Product wissen ?"}
                        onConfirm={event => this.deleteProduct()}
                        onCancel={() => {
                            this.setState({showConfirmDeleteAlert: false, selectedProduct: undefined});
                        }}
                        confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                        cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.cancel}>
                        Bent u zeker ? Deze actie kan niet ongedaan gemaakt worden.
                    </SweetAlert>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <Assignment/>
                                    </CardIcon>
                                </CardHeader>
                                <CardBody>
                                    <Button component={Link} color="rose" className="addProductButton"
                                            to={{pathname: "/admin/add-product"}}>
                                        <FormattedMessage id="productstable.button.createProduct"
                                                          defaultMessage="Add product"/>
                                    </Button>
                                    <Clearfix/>
                                    <ReactTable
                                        data={this.state.data}
                                        onFetchData={(state, instance) => {
                                            this.loadProductData(state.page, state.pageSize, state.filtered)
                                        }}
                                        filterable
                                        columns={[
                                            {Header: "Artikel nummer", accessor: "articleNumber"},
                                            {Header: "Categorie", accessor: "category"},
                                            {Header: "Naam", accessor: "name"},
                                            {Header: "Status", accessor: "status"},
                                            {Header: "Prijs", accessor: "price", filterable: false},
                                            {Header: "", accessor: "actions", sortable: false, filterable: false}
                                        ]}
                                        defaultPageSize={50}
                                        showPaginationTop={false}
                                        showPaginationBottom
                                        className="-striped -highlight"
                                        noDataText=""
                                        rowsText=""
                                        previousText="Vorige"
                                        nextText="Volgende"
                                        pageText="Pagina"
                                        ofText="van"/>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
    }
}

export default injectIntl(withStyles(productsStyle)(Products));
