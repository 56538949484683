import Calendar from "views/Calendar/Calendar.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import Products from "views/Tables/Products.jsx";
import ProductForm from "views/Forms/ProductForm.jsx";
import AppointmentForm from "views/Forms/AppointmentForm.jsx";
import UserProfile from "views/Forms/UserProfile.jsx";
import FinishedAppointments from "./views/Tables/FinishedAppointments";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import Store from "@material-ui/icons/Store";
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Settings from "@material-ui/icons/Settings";
import CompanySettingsGeneral from "./views/Forms/CompanySettingsGeneral";
import Users from "./views/Tables/Users";
import Specifications from "./views/Tables/Specifications";
import Categories from "./views/Tables/Categories";
import LabelLayouts from "./views/Tables/LabelLayouts";
import PriceTypes from "./views/Tables/PriceTypes";

var dashRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin"
    },
    {
        name: "Mijn profiel",
        path: "/user-page",
        icon: Person,
        component: UserProfile,
        layout: "/admin"
    },
    {
        name: "Showroom",
        collapse: true,
        icon: Store,
        state: "storeCollapse",
        views: [
            {
                path: "/products",
                name: "Producten",
                mini: "P",
                component: Products,
                layout: "/admin"
            },
            {
                path: "/edit-product/:articleNumber",
                name: "ProductForm",
                component: ProductForm,
                invisible: true,
                layout: "/admin"
            },
             {
                 path: "/add-product",
                 name: "ProductForm",
                 component: ProductForm,
                 invisible: true,
                 layout: "/admin"
             }
        ]
    },
    {
        name: "Planning",
        collapse: true,
        icon: DateRange,
        state: "planningCollapse",
        views: [
            {
                path: "/calendar",
                name: "Kalender",
                mini: "K",
                component: Calendar,
                layout: "/admin"
            },
            {
                path: "/appointments/finished",
                name: "Afgewerkte afspraken",
                mini: "AA",
                component: FinishedAppointments,
                layout: "/admin"
            },
            {
                path: "/appointments/:appointmentIdentifier",
                name: "AppointmentForm",
                component: AppointmentForm,
                invisible: true,
                layout: "/admin"
            },
        ]
    },
    {
        name: "Instellingen",
        collapse: true,
        icon: Settings,
        state: "settingsCollapse",
        views: [
            {
                path: "/general",
                name: "Algemeen",
                mini: "A",
                component: CompanySettingsGeneral,
                layout: "/admin"
            },{
                path: "/users",
                name: "Gebruikers",
                mini: "G",
                component: Users,
                layout: "/admin"
            },
            {
                path: "/specifications",
                name: "Product specificaties",
                mini: "PS",
                component: Specifications,
                layout: "/admin"
            },
            {
                path: "/categories",
                name: "Product categorieen",
                mini: "PC",
                component: Categories,
                layout: "/admin"
            },
            {
                path: "/pricetypes",
                name: "Prijs types",
                mini: "PT",
                component: PriceTypes,
                layout: "/admin"
            },
            {
                path: "/labellayouts",
                name: "Label layout",
                mini: "LL",
                component: LabelLayouts,
                layout: "/admin"
            }
        ]
    },
    {
        name: "Afmelden",
        path: "/login-page",
        icon: ExitToApp,
        component: LoginPage,
        layout: "/auth"
    },

];
export default dashRoutes;
